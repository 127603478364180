const TermsAndConditionContent = ({ secondary = true }) => {
  const para = `<div>
  <h1 style="font-size:24px;text-align:center; font-weight:600;margin-bottom:70px;">TERMS AND CONDITIONS</h1>
    <h1
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; font-weight:600 ;">
        <span >1.</span><span
            style="width:20.01pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span><a id="_Ref180162865"><span >The Rules,
            </span></a><a id="_Hlk50358745"><span
                >Application</span></a>, and Interpretation</span>
    </h1>
    <h2
        style="margin-top:7.5pt; margin-bottom:7.5pt; text-align:justify; font-weight:600; ">
        <span >Rules</span>
    </h2>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify">
        <span style="; ">1.1</span><a
            href="#_y2u0jqbkb8ar" style="text-decoration:none"><span
                style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span><span
                style="; ; ">These
                Rules have been established in order to facilitate the
                efficient, fair and orderly</span></a><span
            style="; "> </span><a
            href="#_v226vtuzy2s7" style="text-decoration:none"><span
                style="; ; ">operation
                of the Market operated by GFI Group Pte Ltd
                ("</span><strong><span
                    style="; ; ">GFI</span></strong><span
                style="; ; ">")</span></a><span
            style="; ">.</span>
    </p>
    <h2
        style="margin-top:7.5pt; margin-bottom:7.5pt; text-align:justify;font-weight:600 ; ">
        <span >Application</span>
    </h2>
    <p
        style="margin-top:7.5pt; margin-left:27pt; margin-bottom:7.5pt; text-indent:-27pt; text-align:justify; ; ">
        1.2<span
            style="width:13.1pt; text-indent:0pt; display:inline-block">&#xa0;</span>These
        Rules are applicable to each Participant.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        1.3<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>These
        Rules supplement and form part of the Agreement between GFI and a
        Participant. Each Participant’s access to the Market is conditional on
        acceptance of these Rules and is governed by the applicable Agreement
        and these Rules.
    </p>
    <h2
        style="margin-top:7.5pt; margin-bottom:7.5pt; text-align:justify; font-weight:600; ">
        <span >Interpretation</span>
    </h2>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        1.4<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>The
        terms below have the following meanings in these Rules:
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>Adopted Protocols</strong>" means the functionalities and
        methods of trading prescribed or specified within the Market from time
        to time.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>Affiliate</strong>" means, in respect of GFI, BGC Group Inc.
        and Subsidiaries of BGC Group Inc.; and in respect of a Participant or
        any other party to whom these Rules apply, any company, person,
        partnership or entity controlled by or controlling or in common control
        with that party.&#xa0; A person, company, partnership or entity shall be
        deemed to control another person, company, partnership or entity if the
        former person, company, partnership or entity possesses, directly or
        indirectly, the power to direct, or cause the direction of, the
        management and policies of the other person, company, partnership or
        entity whether through ownership of voting securities or partnership
        interests, representation on its board of directors or similar governing
        body, by contract or otherwise;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>Agency Agreement</strong>" means an agreement under which a
        Participant deals in the market or platform as agent for one or more
        third parties;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>Agreement</strong>"<strong> </strong>means the agreement
        between GFI and a client in respect of the provision of brokerage
        services by GFI and may include terms of business, data terms, brokerage
        agreements, rate cards and any other relevant documents as applicable
        that form the greater onboarding documentation relating to GFI and the
        client, and shall include these Rules;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>Anti-Money Laundering (AML) Requirements</strong>" has the
        meaning given in Rule 18;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>Appeals Committee</strong>" has the meaning given in Rule 9.4;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>Applicable Laws</strong>" means all applicable laws, statutes,
        regulations, rules or requirements of any governmental or regulatory or
        self-regulatory authority or any Clearing House or settlement system
        (including any public guidance, orders or other public directions of a
        regulatory authority), market rules and/or market conventions;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>Applicant</strong>" means a legal person applying to become a
        Participant;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>Authorised User</strong>" means any individual who is
        appointed, employed or authorised by a Participant to have access to the
        Market and (if so authorised by the Participant) to execute Trades on
        the Participant’s behalf via the Platform;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>CEA</strong>" means the Commodity Exchange Act;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>Central Clearing Party</strong>" means Euroclear who act as a
        centralized clearing agent for all Cleared Transactions;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>CFTC</strong>"<strong> </strong>means the U.S. Commodity
        Futures Trading Commission;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>Cleared Transaction</strong>" means, for the purposes of Rule
        11, a Trade matched on the Platform and concluded via voice and
        submitted to a clearing house for clearing and settlement in accordance
        with the terms of the Agreement;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>Commodity Exchange Act</strong>" or CEA means the U.S.
        Commodity Exchange Act as amended from time to time or re-enacted;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify">
        <span style="; ">"</span><strong><span
                style="; ; ">Data Protection
                Laws</span></strong><span
            style="; ">" means the laws of
            Singapore to the extent applicable to GFI including, but not limited
            to, the </span><a href="https://sso.agc.gov.sg/Act/PDPA2012"
            style="text-decoration:none"><span
                style="; ; ">Personal
                Data Protection Act 2012</span></a><span
            style="; ">.</span>
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>Eligibility Criteria</strong>" means the criteria set out in
        Rule 3.3;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>Error Trade</strong>" means an erroneous trade when there is an
        obvious error in any relevant terms, such as size, price, direction or
        Product;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        “<strong>Euroclear</strong>” means Euroclear Bank SA/NV.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>Exempt DCO</strong>" has the meaning given in Rule 11.13.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>Insolvency Event</strong>" means the occurrence of any of the
        following circumstances with respect to:
    </p>
    <ol class="awlist1" style="margin:0pt; padding-left:0pt">
        <li 
            style="margin-top:7.5pt; margin-left:56.7pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
            <span
                style="width:19.47pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
            </span>a Participant or
        </li>
    </ol>
    <p
        style="margin-top:7.5pt; margin-left:56.7pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        (ii)<span
            style="width:17.25pt; text-indent:0pt; display:inline-block">&#xa0;</span>where
        a Participant deals as agent on the system, its principal:
    </p>
    <p
        style="margin-top:7.5pt; margin-left:85.05pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        (a)<span
            style="width:16.13pt; text-indent:0pt; display:inline-block">&#xa0;</span>a
        voluntary arrangement or composition or reconstruction of its debts;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:85.05pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        (b)<span
            style="width:16.13pt; text-indent:0pt; display:inline-block">&#xa0;</span>the
        presentation of an administration petition;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:85.05pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        (c)<span
            style="width:16.69pt; text-indent:0pt; display:inline-block">&#xa0;</span>its
        winding-up or dissolution;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:85.05pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        (d)<span
            style="width:16.13pt; text-indent:0pt; display:inline-block">&#xa0;</span>the
        appointment of a liquidator, trustee, receiver, administrative receiver
        or similar officer;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:85.05pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        (e)<span
            style="width:16.13pt; text-indent:0pt; display:inline-block">&#xa0;</span>a
        voluntary or involuntary petition for a bankruptcy order; or
    </p>
    <p
        style="margin-top:7.5pt; margin-left:85.05pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        (f)<span
            style="width:18.91pt; text-indent:0pt; display:inline-block">&#xa0;</span>any
        similar action, application or proceeding in any jurisdiction under
        Applicable Law to which it is subject, provided that in the case of any
        proceeding instituted against it, such proceeding must not be stayed or
        dismissed within thirty (30) days of the institution thereof.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>Instruction</strong>" means any request for quote, order,
        direction or instruction from a Participant;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>LEI</strong>" means a validated, issued and (if relevant) duly
        renewed ISO 17442 legal entity identifier code assigned to a
        Participant;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>Loss</strong>" means any loss, cost, liability, expense or
        damage, including, without limitation, legal and other professional fees
        and expenses;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>Market</strong>" means the MAS regulated environment that GFI
        is licensed to operate under its RMO licence;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>Market Abuse</strong>" means any behavior that constitutes
        market abuse, market manipulation or insider trading, or any other
        similar or analogous behavior prohibited or subject to sanctions or
        penalties under Applicable Laws;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>Market Notice</strong>" has the meaning set out in Rule 23.2;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>MAS</strong>" means the Monetary Authority of Singapore;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>Parameters</strong>" means the parameters or conditions for
        execution of trades which are set by a Participant, and which may
        include trading terms such as target price, size and number of
        responses;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>Participant</strong>" means any entity&#xa0; that is party to a
        Agreement that has met the Eligibility Criteria and who has been
        permitted by GFI to access the Market via the Platform;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>Participating Instruments</strong>" means the financial
        instruments that are available for trading in the Market;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify">
        <span style="; ">"</span><strong><span
                style="; ; ">Personal
                Data</span></strong><span
            style="; ">" has the meaning given
        </span><span
            style="; ; ">under
            the </span><a href="https://sso.agc.gov.sg/Act/PDPA2012"
            style="text-decoration:none"><span
                style="; ; ">Personal
                Data Protection Act 2012</span></a><span
            style="; "> in Singapore</span><span
            style="; ; ">;</span>
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>Platform</strong>" means the electronic matching and trading
        platform operated by Deon Technologies Pte Ltd;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>Processing</strong>" has the meaning given under the <span
            style="">Personal Data Protection Act
            2012</span>;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>Product</strong>" means the categories of Participating
        Instruments as listed on the Platform from time to time;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>Regulator</strong>" means any regulatory authority with
        responsibility for the regulation of GFI or a Participant;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>RMO</strong>" means recognised market operator as defined under
        the Securities and Futures Act 2001;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>Rules</strong>" means the rules set out in this document and
        reference to a specific Rule must be interpreted accordingly;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>Service Provider</strong>" means any person, including any
        Affiliate of a Participant, who provides services enabling a Participant
        to access, use or trade in the Market or to which a Participant
        outsources or delegates an activity in relation to accessing, using or
        trading in the Market;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        “<strong>Subsidiaries</strong>” means, as of the relevant date of
        determination, with respect to any person, any other person of which 50%
        or more of the voting power of the outstanding voting equity securities
        (which, for the avoidance of doubt, shall include a general partner
        interest) or 50% or more of the outstanding economic equity interest is
        held, directly or indirectly, by such person.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>Suspicious Trade</strong>" means a transaction or behavior that
        GFI suspects or believes amounts to Market Abuse, system abuse or misuse
        and/or GFI suspects or believes is or may be manipulative or deceptive,
        or part of a manipulative or deceptive scheme;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>Trade</strong>"<strong> </strong>means a trade executed in the
        Market via the Platform;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>Trading Day</strong> means a day on which the Market is
        available for trading;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>User Guide</strong>" means the help and information pages that
        are available within the Platform explaining the correct method of
        operation of the Platform;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        "<strong>User ID</strong>" means the password and identification
        criteria that are given to each Authorised User by GFI.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        1.5<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>In
        these Rules, words in the singular include the plural and vice versa.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        1.6<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>Headings
        are for ease of reference only.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        1.7<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>References
        to a law or regulation include any supporting statutory provisions or
        publicly available guidance and any amendment, modification or
        re-enactment to such law or regulation.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        1.8<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>For
        certain Products, there may be additional Product-specific requirements
        and protocols which will be applicable only to those Participants who
        trade in that Product. In each such case, the relevant Participant will
        be notified of such Product-specific protocols and requirements and
        their applicability to it, prior to it being able to trade the Product
        concerned. Such protocols and requirements form part of the Rules and
        accordingly are binding on Participants.
    </p>
    <h1
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; font-weight:600; ">
        <span >2.</span><span
            style="width:20.01pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span><span >Capacity and Status</span>
    </h1>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        2.1<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        is regulated in Singapore by the MAS and is authorised to operate a
        market in which Participants may trade Instruments that are available
        for trade and direct clearing.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        2.2<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>These
        Rules supplement and form part of the Agreement entered into by each
        Participant, which is a legally binding contract between the Participant
        and GFI. Each Participant’s access to the Market is governed by these
        Rules and the Agreement.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        2.3<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>In
        the event of any inconsistency, these Rules will prevail over any
        protocols, requirements and notices provided on or through the Platform.
    </p>
    <h1
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify;font-weight:600 ; ">
        <span >3.</span><span
            style="width:20.01pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span><span >Participation in the
            Market</span>
    </h1>
    <h2
        style="margin-top:7.5pt; margin-bottom:7.5pt; text-align:justify;font-weight:600 ; ">
        <span >Admission</span>
    </h2>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        3.1<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>Applicants
        may apply to GFI to become a Participant of the Market at any time.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        3.2<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>Applicants
        wishing to become Participants in the Market must:
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        3.2.1<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>pass
        any required know your customer ("<strong>KYC</strong>") checks,
        sanctions and anti-money laundering ("<strong>AML</strong>") checks
        (including providing such additional information as GFI may require to
        satisfy such checks);
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        3.2.2<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>meet
        the criteria set out in Rule 3.3 (the "<strong>Eligibility
            Criteria</strong>");
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        3.2.3<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>enter
        into an Agreement with GFI; and
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        3.2.4<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>meet
        the operational conditions for access to the Platform set out in Rule
        3.4.
    </p>
    <h2
        style="margin-top:7.5pt; margin-bottom:7.5pt; text-align:justify;font-weight:600 ; ">
        <span >Eligibility Criteria</span>
    </h2>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        3.3<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>A
        Participant must satisfy the following criteria:
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        3.3.1<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>it
        is of sufficient good repute, as determined by GFI in its sole
        discretion;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        3.3.2<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>it
        accesses the Market from an establishment maintained in a jurisdiction
        in which GFI is permitted to provide access to the Market, or from an
        establishment maintained in a jurisdiction which does not prohibit the
        provision of cross-border services by GFI;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        3.3.3<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>it
        has the legal and regulatory authority and capacity to transact in,
        clear (where applicable) and settle the Products; and
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        3.3.5<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>it
        meets any additional eligibility criteria that GFI may reasonably
        prescribe from time to time with regards to participation in the Market.
    </p>
    <h2
        style="margin-top:7.5pt; margin-bottom:7.5pt; text-align:justify;font-weight:600 ; ">
        <span >Operational Conditions</span>
    </h2>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        3.4<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>Each
        Participant, at its own cost and expense, must:
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        3.4.1<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>have
        systems and controls in place to ensure ongoing compliance with these
        Rules and Applicable Laws and for the monitoring of trading against
        management of risk;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        3.4.2<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>meet
        any technical specifications and standards required by GFI for
        participation in the Market;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        3.4.3<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>undertake
        such conformance testing as GFI may require;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        &#xa0;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        3.4.4<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>have
        sufficient financial resources and trading ability, competence and
        experience to perform effectively and efficiently trading operations
        involving participation in the Market and to meet all obligations
        involved in such participation;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        3.4.5<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>have
        internal systems and controls satisfactory to GFI&#xa0; in place to
        prevent and limit Error Trades and the submission of Erroneous
        Instructions to the Market and to ensure ongoing compliance with, and
        prevent breaches of, Applicable Laws, these Rules and the Agreement; and
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        3.4.6<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>have
        arrangements to ensure the timely clearing and settlement of Cleared
        Transactions which are satisfactory to GFI.
    </p>
    <h2
        style="margin-top:7.5pt; margin-bottom:7.5pt; text-align:justify;font-weight:600 ; ">
        <span >Application</span>
    </h2>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        3.5<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>Applicants
        must submit the following to GFI:
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        3.5.1<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>a
        signed Agreement as applicable to the Participant;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        3.5.2<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>completed
        and executed on-boarding documentation;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        3.5.3<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>its
        LEI and corresponding BIC code for clearing on Euroclear;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        3.5.4<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>a
        confirmation as to the Participants regulatory status in their relevant
        jurisdiction; and
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        3.5.5<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>such
        additional information and/or supporting documentation as GFI may
        reasonably require to enable it to assess whether or not the Applicant
        meets the Eligibility Criteria.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        3.6<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>Once
        GFI has received all of the relevant documentation and information, GFI
        will decide whether or not to admit an Applicant as a Participant in the
        Market.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        3.7<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        may reject an Applicant if GFI considers that:
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        3.7.1<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>to
        accept the Applicant would be inconsistent with the operation and
        maintenance of an efficient, fair and orderly Market; or
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        3.7.2<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>the
        Applicant does not meet the conditions set out in these Rules.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        3.8<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>Where
        a Participant is party to an Agency Agreement they must provide to GFI
        such information regarding its principals as GFI may require to fulfil
        its obligations under Applicable Laws.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        3.9<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>An
        Agreement may have provisions that relate to any commercial interaction
        between a Participant and GFI or its Affiliate.
    </p>
    <h2
        style="margin-top:7.5pt; margin-bottom:7.5pt; text-align:justify; font-weight:600; ">
        <span >Continuing Obligations</span>
    </h2>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        3.10<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>Each
        Participant must always ensure its continuing compliance with these
        Rules and with all Applicable Laws to which the Participant is subject.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        3.11<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>A
        Participant must:
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        3.11.1<span
            style="width:7.65pt; text-indent:0pt; display:inline-block">&#xa0;</span>provide
        any information which GFI reasonably requires to meet its obligations
        under these Rules or under Applicable Laws. Any such information must be
        provided as soon as technologically practicable upon request and must be
        accurate and complete;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        3.11.2<span
            style="width:7.65pt; text-indent:0pt; display:inline-block">&#xa0;</span>ensure
        that its Authorised Users, principals, employees or agents provide any
        consents required under Applicable Laws for providing such information
        to GFI and for GFI to Process, use and disclose such information in
        accordance with this Rulebook and in such manner as disclosed in advance
        by GFI to the Participant;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        3.11.3<span
            style="width:7.65pt; text-indent:0pt; display:inline-block">&#xa0;</span>ensure
        that all of its Authorised Users, employees or agents with access to the
        Market or Platform are fit and proper, suitable, appropriately and
        adequately trained or qualified and properly supervised; and
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        3.11.4<span
            style="width:7.65pt; text-indent:0pt; display:inline-block">&#xa0;</span>procure
        that any Service Provider complies with these Rules and with all
        Applicable Laws to which the Participant and the Service Provider are
        subject when accessing, using the Platform or trading in the Market.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        3.12<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>In
        the event of any information request, investigation or other action by
        any Regulator or other authority conducted in relation to use of, or
        trading in the Market, a Participant must co-operate with GFI and the
        relevant investigating regulator or authority by providing access to
        information and documents and by meeting any other reasonable request,
        except where such Participant is restricted from doing so by Applicable
        Laws.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        3.13<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>A
        Participant will be responsible for any acts and omissions of its
        Service Providers in relation to such Service Provider’s use of the
        Platform and/or activities in the Market. GFI will not be liable for any
        losses that a Participant may suffer as a result of the acts or
        omissions of any Service Provider.
    </p>
    <h1
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; font-weight:600; ">
        <span >4.</span><span
            style="width:20.01pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span><span >Authorised Users</span>
    </h1>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        4.1<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>Each
        Participant must provide GFI with a list of its Authorised Users, and
        details of their access requirements, and update that list as necessary.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        4.2<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>A
        Participant must provide such information about each Authorised User as
        GFI may reasonably request, including (without limitation) the
        nationality(ies), date of birth, first name and surname of the
        Authorised User, such information to be held by GFI in compliance with
        Applicable Law.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        4.3<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        will provide Authorised Users with training as appropriate for their
        level of access to the Platform, and they will then be permitted to
        access the Platform on the Participant’s behalf.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        4.4<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        will provide each Authorised User with a User ID. Each Participant will
        be responsible for validating the identity of each of its Authorised
        Users and monitoring the use of its User IDs.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        4.5<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>A
        Participant must ensure that:
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        4.5.1<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>each
        Authorised User is provided with the Rules and all other materials
        relevant to such Authorised User’s use of Platform and access to the
        Market;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        4.5.2<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>each
        Authorised User has authority to use the Platform on its behalf;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        4.5.3<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>the
        User ID given to each Authorised User by GFI is kept confidential to
        such individual and not disclosed to or used by any other person; and
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        4.5.4<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>each
        Authorised User complies with these Rules and with all Applicable Laws
        in relation to its use of the Platform and access to the Market.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        4.6<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>In
        respect of business conducted in the Market via the Platform or any
        other business related thereto:
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        4.6.1<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        will be entitled to rely on, and a Participant will be bound by, any
        Instruction which GFI reasonably believes in good faith to be from an
        Authorised User;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        4.6.2<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>until
        GFI receives written notice to the contrary, GFI is entitled to assume
        that an Authorised User has and continues to have full and unrestricted
        power to submit Instructions on a Participant’s behalf. Notwithstanding
        the foregoing, a Participant must provide any evidence that GFI may
        require of the authority of any person to act on its behalf; and
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        4.6.3<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>a
        Participant will be responsible for the acts and conduct of all
        Authorised Users as if the acts and conduct of each of those persons
        were the acts and conduct of the Participant.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        4.7<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>A
        Participant will be responsible for a breach of a relevant obligation
        committed by its Authorised Users, and GFI may, acting reasonably, take
        measures under these Rules and the Agreement, including suspension or
        termination of the Participant’s access<a id="_wzk9j53fuko2"></a>.
    </p>
    <h1
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; font-weight:600; ">
        <span >5.</span><span
            style="width:20.01pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span><span >Trading Rules</span>
    </h1>
    <h2
        style="margin-top:7.5pt; margin-bottom:7.5pt; text-align:justify; font-weight:600; ">
        <span >General</span>
    </h2>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        5.1<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>The
        Market will be open for business on Trading Days and will operate during
        the hours determined by GFI from time to time.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        5.2<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>Each
        Participant is responsible for any transaction it executes in the
        Market.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        5.3<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>Participants’
        Instructions must be submitted to the Market via the Platform in
        accordance with the relevant requirements of GFI and the relevant
        Adopted Protocols.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        5.4<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        will monitor the operation and use by Participants of the Platform.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        5.5<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        will exercise rights and discretions in relation to these Rules acting
        reasonably at all times in the circumstances.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        5.6<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        will ensure that its website is updated with the latest version of the
        Rules as may be changed from time to time.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        5.7<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        will give Participants reasonable notice of any material change to this
        Rulebook where it is practical to do so but, may make changes without
        prior notice where it considers it necessary in order to preserve the
        fair and orderly operation of the Market. GFI will use reasonable
        endeavors to notify Participants of any such changes as soon as
        practicable.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        5.8<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>Each
        Participant acknowledges and agrees that, to the extent that GFI is
        required by Applicable Laws or requested by a Regulator to disclose,
        make available or report any data or information relating to any
        activity in the Market, GFI may do so in accordance with such Applicable
        Laws or request.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        <strong>Submission and Execution of Instructions</strong>
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        5.9<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>Instructions
        are submitted to the Platform at the Participant’s own risk.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        5.10<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        may reject Instructions which exceed predetermined volume and price
        thresholds relating to a Participant, Product or use of the Market or
        which GFI considers to be clearly erroneous.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        <strong>Requests</strong>
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        5.11<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        may provide, from time to time, the additional functionality on the
        Platform to place price "<strong>Requests</strong>" such as, but not
        limited to, Request-for-Quotes ("<strong>RFQ</strong>"),
        Request-for-Match ("<strong>RFM</strong>") or Request-for-Stream
        ("<strong>RFS</strong>").
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        5.12<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        may monitor Participants’ use of the Market and/or Platform to assess
        whether such Participants are sending a substantial number of Requests
        which do not result in completed matches.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        5.13<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        may, at its absolute discretion, maintain individual hit rate policies
        for certain Products, as may be notified to those Participants that are
        active in that Product. Any change of such policies shall be notified by
        the sending of a Market Notice to the Participants that use the relevant
        Products.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        <strong>&#xa0;</strong>
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        <strong>Transparency</strong>
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        5.14<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>The
        Market and/or the Platform may be subject to pre-trade and post-trade
        transparency requirements in accordance with applicable laws and
        regulations.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        5.15<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>Information
        required to be disclosed under GFI’s transparency obligations will be
        disclosed and made available to the public as required by Applicable
        Laws.
    </p>
    <p
        style="margin-top:7.5pt; margin-bottom:7.5pt; text-align:justify; ; ">
        <strong>Instrument Eligibility</strong>
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        5.16<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        will determine the Products and the Participating Instruments within
        each such Product that are available for trading in the Market via the
        Platform from time to time.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        5.17<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>The
        decision whether or not to admit an instrument to trading is at the sole
        discretion of GFI.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        5.18<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        may remove or suspend Products and Participating Instruments without
        notice if GFI deems it necessary in order to maintain a fair and orderly
        Market, to comply with Applicable Laws or in response to a request from
        a regulator or for any other reason at its sole discretion.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        5.19<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>Where
        futures trades are arranged, crossed or blocked onto a relevant exchange
        or Clearing House, minimum size and deal input time limits and any other
        applicable rules will need to be adhered to by both the Market, the
        Platform and the Participants.
    </p>
    <h1
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; font-weight:600; ">
        <span >6.</span><span
            style="width:20.01pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span><span >Disorderly Trading Conditions and
            Conduct</span>
    </h1>
    <h2
        style="margin-top:7.5pt; margin-bottom:7.5pt; text-align:justify; font-weight:600; ">
        <span >Prohibition on Market Abuse and
            Disorderly Trading</span>
    </h2>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        6.1<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>A
        Participant must not under any circumstances:
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        6.1.1<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>engage
        in Market Abuse when using the Market or the Platform or engage in
        Market Abuse in relation to any Participating Instrument;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        6.1.2<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>engage
        in any conduct in the Market or on the Platform which is intended or
        designed to, or results in the creation of a false or misleading
        impression as to, the market in or price of any Participating Instrument
        or which results in the price of one or more Participating Instruments
        being secured at an abnormal or artificial level;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        6.1.3<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>engage
        in any conduct or activity in the Market or on the Platform which harms
        or is likely to harm the integrity, fairness, orderliness or reputation
        of the Market;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        6.1.4<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>submit
        Instructions to, or execute transactions which are fictitious;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        6.1.5<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>otherwise
        engage in any other form of deceptive, manipulative, contriving or
        abusive practice or any other practice prohibited by Applicable Laws in
        the Market or on the Platform; or
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        6.1.6<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>breach
        or attempt to breach these Rules.
    </p>
    <h1
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; font-weight:600; ">
        <span >7.</span><span
            style="width:20.01pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span><span >Prevention of Disorderly Trading
            Conditions</span>
    </h1>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        7.1<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        may maintain such arrangements as it considers appropriate to prevent
        disorderly trading and breaches of capacity limits, including:
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        7.1.1<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>mechanisms
        to manage volatility; and
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        7.1.2<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>pre-trade
        controls.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        7.2<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>Without
        limitation to the foregoing, GFI may:
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        7.2.1<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>request
        information from any Participant on the mechanisms it has in place to
        manage volatility and its trading controls;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        7.2.2<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>suspend
        a Participant’s or an Authorised User’s access to the Market and/or
        Platform, at the initiative of GFI or at the request of the Participant
        or a Regulator;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        7.2.3<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>operate
        a functionality to cancel unexecuted Instructions submitted by or on
        behalf of a Participant, including:
    </p>
    <p
        style="margin-top:7.5pt; margin-left:85.05pt; margin-bottom:7.5pt; text-indent:-21.25pt; text-align:justify; ; ">
        (a)<span
            style="width:9.03pt; text-indent:0pt; display:inline-block">&#xa0;</span>upon
        request of the Participant;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:85.05pt; margin-bottom:7.5pt; text-indent:-21.25pt; text-align:justify; ; ">
        (b)<span
            style="width:9.03pt; text-indent:0pt; display:inline-block">&#xa0;</span>where
        erroneous, or duplicated orders have been submitted via the Platform; or
    </p>
    <p
        style="margin-top:7.5pt; margin-left:85.05pt; margin-bottom:7.5pt; text-indent:-21.25pt; text-align:justify; ; ">
        (c)<span
            style="width:9.59pt; text-indent:0pt; display:inline-block">&#xa0;</span>following
        a suspension initiated either by GFI or a Regulator;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        7.2.4<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>cancel
        or revoke transactions in case of malfunction of mechanisms designed to
        manage volatility or of the operational functions of the Platform; and
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        7.2.5<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>balance
        entrance of Instructions among thedifferent gateways, where the Platform
        uses more than one gateway in order to avoid technology related issues.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        7.3<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>Participants
        must operate their own functionality to enable prompt and orderly
        cessation of activities in the Market or on the Platform, where required
        by Applicable Law.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        7.4<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        may publish and amend policies and arrangements in respect of mechanisms
        to mitigate the risk of market disruption from time to time. Such
        policies and arrangements, which shall be supplied or notified to
        Participants in advance of coming into effect, apply to and are binding
        on Participants as part of these Rules.
    </p>
    <h2
        style="margin-top:7.5pt; margin-bottom:7.5pt; text-align:justify; font-weight:600; ">
        <span >Monitoring</span>
    </h2>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        7.5<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        will monitor Participants’ use of the Platform and access to the Market,
        including Instructions sent, cancellations and transactions undertaken,
        for signs of disorderly trading conditions, conduct that may involve
        Market Abuse and/or manipulation, Suspicious Trades and non-compliance
        with these Rules.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        7.6<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>By
        participating in the Market a Participant acknowledges that GFI is
        entitled to retain records of Participants’ use of the Platform and to
        report, and provide full assistance, to any regulatory authorities in
        relation to suspected infringements of these Rules, disorderly trading
        conditions or conduct that may indicate Market Abuse or any other
        behaviour&#xa0; prohibited by Applicable Laws.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        7.7<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        may conduct a review of a transaction to determine whether such
        transaction is a Suspicious Trade where it considers it prudent to do
        so. In determining whether a transaction is a Suspicious Trade, GFI may
        utilise such information and consult with any relevant parties that it
        deems appropriate.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        7.8<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>In
        the event that GFI determines a Trade is a Suspicious Trade, it may take
        such action as it deems necessary or appropriate in the circumstances.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        7.9<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        will not be liable for any Losses that a Participant suffers as a result
        of a decision, act or omission pursuant to this Rule 7.
    </p>
    <h1
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; font-weight:600; ">
        <span >8.</span><span
            style="width:20.01pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span><span >Trading Halts</span>
    </h1>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        8.1<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>In
        addition to, and without prejudice to the extent of, its powers under
        Rule 9, GFI may temporarily halt or constrain trading in the Market or
        via the Platform in:
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        8.1.1<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>Participating
        Instruments within a specific industry group;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        8.1.2<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>Participating
        Instruments which fall within a geographical sector or are listed on a
        specified market; or
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        8.1.3<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>the
        whole market, or cancel, vary or correct any transaction, where GFI
        considers a significant price movement has occurred in a Participating
        Instrument during a short period of time though, in coming to any such
        decision will at all times act reasonably having regard to the possible
        damage this may cause to Participant’s interests.
    </p>
    <h1
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; font-weight:600; ">
        <span >9.</span><span
            style="width:20.01pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span><span >Suspension or Removal of
            Products, Participating Instruments or Participants</span>
    </h1>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        9.1<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        may, at any time, suspend trading in the Market or on the entire
        Platform, or suspend or remove one or more Products or Participating
        Instruments, where GFI reasonably considers that such action is
        necessary:
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        9.1.1<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>to
        ensure fair and orderly trading in the Market and on the Platform;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        9.1.2<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>to
        uphold the integrity and stability of the overall Market and on the
        Platform;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        9.1.3<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>where
        a Participating Instrument no longer complies with these Rules unless
        such suspension or removal would be likely to cause significant damage
        to the Participants’ interests or to the orderly functioning of the
        Market;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        9.1.4<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>to
        comply with instructions from a Regulator; or
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        9.1.5<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>to
        comply with the requirements of any Applicable Law.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        9.2<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        may suspend or remove a Participant’s access to the Market or the
        Platform in accordance with the terms of these Rules or the
        Participant’s Agreement or otherwise, or take any other disciplinary
        action as GFI, in its sole discretion acting reasonably, deems necessary
        or appropriate, if:
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        9.2.1<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>the
        Participant or its Authorised User breaches any of these Rules;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        9.2.2<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>the
        Participant&#xa0; or its Authorised Users breaches a term of its
        Agreement;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        9.2.3<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>the
        Participant’s Agreement is terminated;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        9.2.4<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>the
        Participant has made a misrepresentation to GFI or another Participant
        or has otherwise breached Applicable Laws in any material respect;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        9.2.5<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>an
        Insolvency Event has occurred in relation to a Participant;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        9.2.6<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>the
        Participant ceases to meet the Eligibility Criteria;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        9.2.7<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>the
        Participant repudiates or rejects one (1) or more of its transactions on
        the Platform;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        9.2.8<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>the
        Participant attempts to enter or is party to a Suspicious Trade;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        9.2.9<span
            style="width:13.21pt; text-indent:0pt; display:inline-block">&#xa0;</span>the
        Participant refuses to provide information or records requested by GFI
        under Rule 19.5; or
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        9.2.10<span
            style="width:7.65pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI,
        in its sole discretion, deems it necessary to ensure a fair and orderly
        market and to uphold the integrity of the Market or the Platform, or to
        comply with Applicable Laws, or if so required by a Regulator.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        9.3<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        will notify a Participant of a decision taken under this Rule 9 unless
        it is prohibited from doing so by Applicable Laws.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        9.4<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>A
        Participant may appeal a decision taken under Rule 9.2 by submitting in
        writing to GFI its reasons for appealing and any information relevant to
        the appeal. A Participant must submit its appeal in writing to the chief
        compliance officer of GFI within a reasonable time of receiving notice
        from GFI under Rule 9.3. The chief compliance officer of GFI (or his/her
        delegate) will consider the decision of GFI, which is the subject of the
        appeal, through consultation of an appeals committee set up specifically
        for this purpose, including any advisers deemed necessary (the
        "<strong>Appeals</strong> <strong>Committee</strong>").
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        The Appeals Committee will take any other steps it considers appropriate
        including holding pre-hearing reviews for the clarification of the facts
        and issues and generally for their just, efficient and expeditious
        presentation and the proper determination of the appeal and it will only
        quash or vary a decision taken by GFI under Rule 9.2 if it is satisfied,
        on the balance of probabilities, that the decision is a
        misinterpretation of or an erroneous application of these Rules or is
        not justified by the evidence on which it is based and will notify the
        Participant of its decision within 15 business days of reaching a
        decision with the reasons set out therewith. The decision of the chief
        compliance officer (or his/her delegate) in consultation with and as
        decided by the Appeals Committee will be final.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        9.5<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>A
        Participant whose right to access the Market or use the Platform has
        been suspended or terminated will remain subject to these Rules in
        respect of any acts and/or omissions while it was a Participant and will
        comply with any request for information in relation to the period of its
        participation which GFI may make at any time following the termination
        of its participation.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        9.6<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>A
        Participant whose right to access the Market or use the Platform has
        been suspended or terminated will remain subject to these Rules in
        respect of any outstanding obligations under these Rules until those
        obligations are satisfied.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        9.7<span
            style="width:14.45pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        will not be liable for any Losses that a Participant suffers as a result
        of a decision or action taken under this Rule 9.
    </p>
    <h1
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; font-weight:600; ">
        <span >10.</span><span
            style="width:14.45pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span><span >Post-trade</span>
    </h1>
    <h2
        style="margin-top:7.5pt; margin-bottom:7.5pt; text-align:justify; font-weight:600; ">
        <span >Contractual Position and Disputes</span>
    </h2>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        10.1<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>Subject
        to any other agreement between the relevant two Participants, when a
        transaction is confirmed as "accepted" (or such other term that is used
        to designate a transaction has occurred in accordance with the relevant
        Adopted Protocol) on the Platform, this will be conclusive evidence of
        the Participants having entered into a valid, legal and binding
        transaction.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        10.2<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>All
        transactions in the Market are effected on a bilateral basis between the
        relevant two Participants, creating rights and obligations between those
        Participants, subject to any terms agreed between the Participants
        relating to clearing. Unless otherwise contemplated by these Rules, GFI
        will not be, directly or indirectly, party to, or responsible or liable
        to a Participant under or in connection with, such transactions.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        10.3<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>In
        the event of a dispute between Participants regarding a transaction
        executed in the Market via the Platform, GFI may provide such
        Participants, upon request, with such documentation and information
        pertaining to the transaction which is available to GFI. This will be
        subject to any regulatory or legal obligation which prevents GFI from
        disclosing any such documentation and/or information. GFI will, in
        accordance with Applicable Law, endeavour to cooperate in resolving such
        dispute where able to do so on a limited disclosure basis if material
        information is in GFI’s possession that would likely accelerate this
        process.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        <strong>Trade Cancellation</strong>
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        10.4<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        reserves the right to require the parties to cancel any Instruction
        submitted or transaction executed on the Platform where GFI reasonably
        considers that:
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        10.4.1<span
            style="width:7.65pt; text-indent:0pt; display:inline-block">&#xa0;</span>the
        Instruction or transaction appears to be Market Abuse or otherwise
        manipulative or indicative of system abuse or misuse;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        10.4.2<span
            style="width:7.65pt; text-indent:0pt; display:inline-block">&#xa0;</span>the
        Instruction or transaction appears to be deceptive or fraudulent;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        10.4.3<span
            style="width:7.65pt; text-indent:0pt; display:inline-block">&#xa0;</span>such
        cancellation is necessary to preserve the integrity of the overall
        market and maintain fair and orderly trading;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        10.4.4<span
            style="width:7.65pt; text-indent:0pt; display:inline-block">&#xa0;</span>the
        Instruction or transaction was entered into following: (a) the
        suspension or limitation on trading in a Participating Instrument, (b) a
        significant disruption or malfunction in the use or operation of any
        electronic communications, trading facilities or price determination
        functions of the Platform, (c) failure of a central counterparty or (d)
        other unusual market conditions; or
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        10.4.5 the Instruction or transaction is contrary to Applicable Laws.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        10.5<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>Action
        taken under Rule 10.4 will be binding on Participants. GFI will notify
        Participants affected by its decision unless prohibited by Applicable
        Laws.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        10.5<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>Each
        Participant acknowledges and agrees that GFI is not obliged to require
        the parties to cancel any Instructions or transactions in the Market or
        submitted via&#xa0; the Platform.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        10.6<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>If
        GFI reasonably determines a transaction should be cancelled in
        accordance with Rule 10.4, the relevant Participants must take such
        steps as may be reasonably necessary to effect such cancellation in
        accordance with normal market convention.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        10.7<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        will not be liable for any Losses that a Participant suffers as a result
        of a decision, act or omission pursuant to Rule 10.4.
    </p>
    <h2
        style="margin-top:7.5pt; margin-bottom:7.5pt; text-align:justify; font-weight:600; ">
        <span >Error Trades</span>
    </h2>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        10.8<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>A
        Participant must notify GFI of a potential Error Trade as soon as
        practicable after the execution of the transaction in question.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        10.9<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>If
        a Participant believes that an executed transaction is a potential Error
        Trade, the Participant may request that GFI reviews such potential Error
        Trade, whereupon GFI will investigate the potential Error Trade to
        assist the relevant Participants with determining if an Error Trade has
        occurred.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        10.10<span
            style="width:3.33pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        will provide such Participants, upon request, with such documentation
        and information pertaining to the transaction which is available to GFI
        to assist the respective Participants to reach an agreement regarding
        their rights and obligations with respect to the potential Error Trade.
        This will be subject to any regulatory or legal obligation which GFI has
        which requires them not to disclose such documentation and/or
        Information.
    </p>
    <h1
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; font-weight:600; ">
        <span >11.</span><span
            style="width:14.45pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span><span >Clearing</span>
    </h1>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        11.1<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>Each
        Participant is responsible for:
    </p>
    <p
        style="margin-top:7.5pt; margin-left:56.7pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        (a)<span
            style="width:16.13pt; text-indent:0pt; display:inline-block">&#xa0;</span>determining
        which executed transactions are required to be cleared;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:56.7pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        (b)<span
            style="width:16.13pt; text-indent:0pt; display:inline-block">&#xa0;</span>submitting
        such transactions for clearing; and
    </p>
    <p
        style="margin-top:7.5pt; margin-left:56.7pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        (c)<span
            style="width:16.69pt; text-indent:0pt; display:inline-block">&#xa0;</span>executing
        all applicable agreements and arrangements necessary for clearing such
        transactions in accordance with Applicable Laws. GFI is not responsible
        or liable for the clearing of transactions executed by a Participant in
        the Market via the Platform. However, GFI may assist Participants (if
        so, requested by the Participant) by providing additional functionality
        in the Platform and the details of any such transaction to the chosen
        clearing member, clearing house or middleware provider in order to
        facilitate the clearing of transactions, where permitted by Applicable
        Law.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        11.2<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>Each
        Participant must designate in the the Platform each clearing house that
        it wishes to use to submit Cleared Transactions and designate a clearing
        member (which may include itself where it is self-clearing) for a
        particular Cleared Transaction.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        11.3<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>Prior
        to submitting an Instruction in relation to a potential Cleared
        Transaction, the Participant must procure, where necessary,
        pre-conclusion screening on an Instruction-by-Instruction basis against
        the limits set and maintained by the relevant clearing member if so
        required by the Central Clearing Party.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        11.4<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>In
        the event that an Instruction in relation to a potential Cleared
        Transaction fails the pre conclusion screening undertaken by a
        Participant under Rule 11.3, the Participant should not submit such
        Instruction.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        11.5<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>The
        clearing services provided by each clearing house designated under Rule
        11.2 with respect to any transaction accepted for clearing, and the
        rights and obligations of purchasers and sellers under such transaction
        (including rights and obligations in respect of clearing and settlement,
        variation payments and performance at maturity), will be governed by the
        rules of the relevant clearing house.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        11.6<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>Each
        Participant permits GFI to pass information concerning the Participant’s
        use of the Market and/or Platform, including any relevant trade data to
        relevant clearing houses, clearing member(s), third party middleware
        providers and the counterparty that executes the contract with the
        Participant to the extent reasonably necessary for the clearing or
        settlement of transactions entered into by such Participant. The
        Participant expressly licenses GFI to provide this information to such
        entities and acknowledges and agrees that GFI is simply acting on behalf
        of the Participant in transmitting such information.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        11.7<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>Where
        functionality in the Platform provides for the clearing of Cleared
        Transactions, a Participant must provide such information as GFI may
        require in the format specified by GFI in order for GFI to submit the
        transaction to the relevant clearing house for clearing. Such
        information must be provided to GFI during the on-boarding process for
        setting clearing access.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        11.8<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>Where
        functionality in the Platform provides for the clearing of Cleared
        Transactions, GFI will provide facilities to route information relating
        to such Cleared Transactions to those clearing houses that have
        established operational links to the Platform either directly or through
        a partner provider.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        11.9<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>Where
        a Cleared Transaction concluded on the Platform and submitted for
        clearing is not accepted for clearing in accordance with the rules and
        procedures of the relevant clearing house, GFI will take reasonable
        steps to notify Participants of the non-acceptance of the transaction
        and GFI will use reasonable endeavors to determine the reason for the
        non-acceptance.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        11.9.1<span
            style="width:7.65pt; text-indent:0pt; display:inline-block">&#xa0;</span>Where
        the Cleared Transaction was not accepted for clearing as a result of a
        credit issue, the Cleared Transaction will be deemed void and GFI will
        provide notice of such determination to the relevant Participants as
        soon as reasonably practicable.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:63.8pt; margin-bottom:7.5pt; text-indent:-35.45pt; text-align:justify; ; ">
        11.9.2<span
            style="width:7.65pt; text-indent:0pt; display:inline-block">&#xa0;</span>Where
        GFI determines that the Cleared Transaction was not accepted for
        clearing as a result of a technical or clerical problem, GFI will assess
        whether it can re-submit the Cleared Transaction to the clearing house
        in accordance with Rule 11.10 below.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        11.10<span
            style="width:3.33pt; text-indent:0pt; display:inline-block">&#xa0;</span>Upon
        making a determination under Rule 11.9.2 that the Cleared Transaction
        was rejected from clearing as a result of a technical or clerical
        problem, GFI will use reasonable endeavors to assess, whether it is able
        to correct the technical or clerical problem.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        11.10.1<span
            style="width:9.19pt; text-indent:0pt; display:inline-block">&#xa0;</span>Where
        GFI considers that it is able to correct the technical or clerical
        problem, GFI may re-submit to the clearing house the Cleared Transaction
        in question (in the form of a new transaction but with the same economic
        terms) within a reasonable timeframe and the counterparties to the
        Cleared Transaction will be deemed to have consented to and shall be
        bound by the re-submission of the transaction by GFI.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        11.10.2<span
            style="width:9.19pt; text-indent:0pt; display:inline-block">&#xa0;</span>Where
        GFI considers that it cannot correct the technical or clerical problem,
        it may seek guidance from the counterparties to the Cleared Transaction
        in question.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        11.10.3<span
            style="width:9.19pt; text-indent:0pt; display:inline-block">&#xa0;</span>Where
        GFI considers that it cannot correct the technical or clerical problem
        and the Cleared Transaction is not able to be re-submitted for clearing,
        then the Cleared Transaction will be deemed void and GFI will take
        reasonable steps to notify Participants accordingly.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        11.11<span
            style="width:3.33pt; text-indent:0pt; display:inline-block">&#xa0;</span>The
        parties to a Cleared Transaction that is deemed void in accordance with
        Rule 11.9.1 or Rule 11.10.3 must not resubmit the transaction to the
        Platform.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        11.12<span
            style="width:3.33pt; text-indent:0pt; display:inline-block">&#xa0;</span>Where
        a Cleared Transaction that is concluded in the Market via the Platform
        is deemed void in accordance with Rule 11.9.1 or Rule 11.10.3, neither
        party to the void transaction will have any recourse to the other or
        will be liable to the other for any Losses, costs, expenses or charges
        suffered with respect to the void transaction.
    </p>
    <h2
        style="margin-top:7.5pt; margin-bottom:7.5pt; text-align:justify; font-weight:600; ">
        <span >US Persons</span>
    </h2>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        11.13<span
            style="width:3.33pt; text-indent:0pt; display:inline-block">&#xa0;</span>The
        following provisions are applicable to any person trading in the Market
        that is deemed by Applicable Laws to be a "<strong>U.S.
            Person</strong>":
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        11.13.1<span
            style="width:9.19pt; text-indent:0pt; display:inline-block">&#xa0;</span>when
        a Trade in a relevant interest rate swap or other financial instrument
        that is executed by a U.S. Person on the Platform is a "customer"
        position and subject to the CEA section 4d, the Trade must be cleared
        through a futures commission merchant registered with the CFTC at a
        clearing house that is also registered with the CFTC;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        11.13.2<span
            style="width:9.19pt; text-indent:0pt; display:inline-block">&#xa0;</span>when
        a Trade in a relevant interest rate swap or other financial instrument
        that is executed by a U.S. Person on the Platform is a
        "<strong>proprietary</strong>" position under the CFTC Regulation
        1.3(y), the Trade must be cleared either through a clearing house
        registered with the CFTC or a clearing organisation that has been
        exempted from registration as a derivatives clearing organisation by the
        CFTC pursuant to the CEA section 5b(h) (an "<strong>Exempt
            DCO</strong>"); and
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        11.13.3<span
            style="width:9.19pt; text-indent:0pt; display:inline-block">&#xa0;</span>when
        a Trade in a relevant interest rate swap or other financial instrument
        that is subject to the CFTC's clearing requirement under Part 50 of the
        CFTC’s regulations, and is entered into by a U.S. Person that, pursuant
        to CEA section 2(h)(l), is subject to such clearing requirement the
        Trade must be cleared either through a clearing house registered with
        the CFTC or an Exempt DCO; provided that if the Trade is a "customer"
        position subject to CEA section 4d, it must be cleared through a futures
        commission merchant at a clearing house registered with the CFTC, and
        cannot be cleared through an Exempt DCO.
    </p>
    <h1
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; font-weight:600; ">
        <span >12.</span><span
            style="width:14.45pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span><span >Settlement</span>
    </h1>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        12.1<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>Each
        Participant is responsible for the settlement of all transactions it
        executes in the Market via the Platform, and each Participant must
        ensure that it has the ability, including legal and regulatory capacity,
        to settle such transactions accordingly.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        12.2<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        is not responsible or liable for the settlement of the transactions
        executed by a Participant in the Market. GFI will, however, provide or
        make available to each Participant the details of each transaction that
        the Participant executes in the Market via the Platform in order to
        facilitate the prompt settlement of these transactions by that
        Participant.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        12.3<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>For
        certain Participating Instruments, a default settlement date may be
        specified in accordance with Applicable Laws. A Participant may change
        the default settlement date, where permitted, in order to conduct a
        transaction involving a different settlement date.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        12.4<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>Each
        Participant, where required by Applicable Laws, must ensure that:
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        12.4.1<span
            style="width:14.75pt; text-indent:0pt; display:inline-block">&#xa0;</span>transactions
        in transferable securities executed in the Market are recorded in book
        entry (dematerialised) form on or before the intended settlement date;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        12.4.2<span
            style="width:14.75pt; text-indent:0pt; display:inline-block">&#xa0;</span>where
        applicable, it takes measures to limit the number of settlement
        failures, including making arrangements to:
    </p>
    <p
        style="margin-top:7.5pt; margin-left:99.25pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        (a)<span
            style="width:16.13pt; text-indent:0pt; display:inline-block">&#xa0;</span>ensure
        the prompt communication of an allocation of securities to the
        transaction;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:99.25pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        (b)<span
            style="width:16.13pt; text-indent:0pt; display:inline-block">&#xa0;</span>confirm
        that allocation;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:99.25pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        (c)<span
            style="width:16.69pt; text-indent:0pt; display:inline-block">&#xa0;</span>confirm
        the acceptance or rejection of terms in good time before the intended
        settlement date;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:99.25pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        (d)<span
            style="width:16.13pt; text-indent:0pt; display:inline-block">&#xa0;</span>settle
        transactions in transferable securities on the intended settlement date
        in compliance with Applicable Laws; and
    </p>
    <p
        style="margin-top:7.5pt; margin-left:99.25pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        (e)<span
            style="width:16.13pt; text-indent:0pt; display:inline-block">&#xa0;</span>comply
        with Applicable Laws relating to buy-ins.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        12.5<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>Each
        Participant must notify GFI by giving written notice within one (1)
        Trading Day of becoming aware of a failure by the Participant or any
        other Participant to settle a transaction in accordance with Applicable
        Laws and/or good settlement practice, as the case may be.
    </p>
    <h1
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; font-weight:600; ">
        <span >13.</span><span
            style="width:14.45pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span><span >Transaction Reporting</span>
    </h1>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        13.1<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>Each
        Participant must be responsible for any disclosure, reporting and/or
        filing requirements that may arise for such Participant under Applicable
        Laws in connection with the Participant’s use of the Platform.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        13.2<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>A
        Participant must notify GFI if it undertakes or intends to commence
        transaction reporting to the MAS.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        13.3<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>Notwithstanding
        Rule 13.1, GFI is authorised to undertake transaction reporting to the
        MAS on behalf of a Participant, where GFI is required to do so by
        Applicable Laws.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        13.4<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>A
        Participant for which GFI reports a transaction on its behalf must not
        report, or cause another to report, the same transaction to the MAS.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        13.5<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>A
        Participant must provide any information required by GFI in order to
        undertake transaction reporting in accordance with Rule 13.3, including,
        but not limited to, all details required, in the format prescribed by
        GFI.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        13.6<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>A
        Participant must provide the information required under Rule 13.5 as
        soon as practicable following the execution of a transaction and, in any
        event, no later than noon (12pm) Singapore Standard Time on the Trading
        Day following execution of the transaction.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        13.7<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>A
        Participant must have controls in place to ensure any information
        provided to GFI under Rule 13.5 is complete and accurate.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        13.8<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>A
        Participant must promptly inform GFI where information provided under
        Rule 13.5 is inaccurate or incomplete and must cooperate with GFI to
        rectify any errors in reports submitted by GFI under Rule 13.3.
    </p>
    <h1
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; font-weight:600; ">
        <span >14.</span><span
            style="width:14.45pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span><span >Sponsored or Direct Market
            Access</span>
    </h1>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        14.1<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>Participants
        must not permit or allow any third party to access the Market or the
        Platform via any means, including via sponsored access or direct market
        access, unless approved by GFI to do so.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        14.2<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        may consider a request from a Participant who wishes to provide
        sponsored access or direct market access to the Market or the Platform.
        GFI may refuse or impose conditions on any approval granted for such a
        request. In the event that GFI approves a Participant’s request to
        provide sponsored access or direct market access, the Participant may be
        required to enter into a sponsored access or direct market access
        agreement with GFI in a form prescribed by GFI.
    </p>
    <h1
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; font-weight:600; ">
        <span >15.</span><span
            style="width:14.45pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span><span >Rights on Default</span>
    </h1>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        15.1<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        may take action that it deems reasonably necessary or appropriate in the
        circumstances and in order to preserve a fair and orderly market and the
        integrity of the Market, including, but not limited to:
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        15.1.1<span
            style="width:14.75pt; text-indent:0pt; display:inline-block">&#xa0;</span>reversing
        or cancelling of any and all outstanding Instructions or instructions
        relating to an Instruction to the extent technologically possible;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        15.1.2<span
            style="width:14.75pt; text-indent:0pt; display:inline-block">&#xa0;</span>suspending
        or terminating a Participant’s right to use the Market or access the
        Platform
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        15.1.3<span
            style="width:14.75pt; text-indent:0pt; display:inline-block">&#xa0;</span>issuing
        written warning or private censure.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify; ; ">
        In taking any of the actions in 15.1.1 to 15.1.3 above GFI will act
        proportionately having regard to the circumstances and the
        reasonableness of such action in view of the possible damage that may be
        caused to a Participant’s interests.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        15.2<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>The
        powers conferred by Rule 15.1 will be exercisable if:
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        15.2.1<span
            style="width:14.75pt; text-indent:0pt; display:inline-block">&#xa0;</span>a
        Participant fails to perform, or states that it is unwilling or unable
        to perform, in a timely manner taking into account any remediation
        period agreed between the Participant and GFI, any obligation owing to
        GFI under these Rules, the Agreement or under any other agreement in
        force between the Participant and GFI or under or in respect of any
        transaction;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        15.2.2<span
            style="width:14.75pt; text-indent:0pt; display:inline-block">&#xa0;</span>a
        Participant disclaims, repudiates or rejects or challenges, in whole or
        in part, the validity of these Rules, the Agreement or the terms of any
        other agreement in force between the Participant and GFI or under or in
        respect of any transaction;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        15.2.3<span
            style="width:14.75pt; text-indent:0pt; display:inline-block">&#xa0;</span>any
        representation or undertaking a Participant has made, or is deemed to
        have made, in respect of these Rules, or any other agreement in force
        between the Participant and GFI or under or in respect of any
        transaction, is breached and the Participant has not complied with any
        remediation period agreed with GFI for such breach;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        15.2.4<span
            style="width:14.75pt; text-indent:0pt; display:inline-block">&#xa0;</span>an
        Insolvency Event has occurred with respect to a Participant or its
        Affiliate; or
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        15.2.5<span
            style="width:14.75pt; text-indent:0pt; display:inline-block">&#xa0;</span>for
        any reason (whether or not similar to the foregoing) GFI reasonably
        considers that the exercise of any of the powers conferred by Rule 15.1
        is necessary or appropriate in the circumstances.
    </p>
    <h1
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; font-weight:600; ">
        <span >16.</span><span
            style="width:14.45pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span><span >Material Interests and
            Conflicts</span>
    </h1>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        16.1<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>Neither
        the relationship between a Participant and GFI nor the provision of
        services by GFI under these Rules or the Agreement will give rise to any
        fiduciary or equitable duties on the part of GFI which would oblige it
        to accept responsibilities more extensive than those set out in these
        Rules.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        16.2<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>If
        GFI receives a payment or other benefit from any person in respect of
        the operation of the Platform or any transaction or use of any
        information relating thereto, it will be authorised and entitled to
        retain any such payment or benefit and will not be liable to account for
        the same to any Participant, and the fees will not be abated thereby.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        16.3<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        has policies and procedures to identify, consider and manage potential
        conflicts of interest and protect the integrity of its relationships
        with the Participants.
    </p>
    <h1
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; font-weight:600; ">
        <span >17.</span><span
            style="width:14.45pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span><span >Compliance with Applicable
            Laws</span>
    </h1>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        17.1<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>All
        Instructions and transactions effected under these Rules are subject to
        Applicable Laws.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        17.2<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>In
        the event of any conflict between these Rules and any Applicable Law,
        the Applicable Law will prevail and, to the extent these Rules or any
        provision thereto will be held to be unenforceable or invalid, the
        relevant provision will, to that extent, be given no effect, but these
        Rules will in all other respects continue in full force and effect.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        17.3<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        and any of its Affiliates are entitled, and are hereby authorised by
        each Participant, to take any action or refrain from taking any action
        which it considers appropriate for the purpose of complying with any
        Applicable Law or with any request of any governmental, regulatory
        authority or Regulated Market.
    </p>
    <h1
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; font-weight:600; ">
        <span >18.</span><span
            style="width:14.45pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span><span >Anti-Money Laundering,
            Anti-Terrorist Financing and Sanctions Compliance</span>
    </h1>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        18.1<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>Each
        Participant must establish and maintain policies and procedures
        reasonably designed to provide for compliance with all Applicable Laws
        to the extent applicable to a Participant, including, without
        limitation, Applicable Laws relating to money laundering and terrorist
        financing (collectively, "<strong>AML Requirements</strong>").
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        18.2<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>Each
        Participant must reasonably cooperate with GFI regarding compliance with
        applicable AML Requirements relating to a transaction effected in the
        Market via the Platform with or for a Participant.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        18.3<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>No
        Participant will use the Market and/or Platform to facilitate any
        criminal or fraudulent activity or engage in any transactions that would
        cause a Participant or GFI (including, for this purpose, any Affiliate
        of GFI) to violate any Applicable Law.
    </p>
    <h1
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; font-weight:600; ">
        <span >19.</span><span
            style="width:14.45pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span><span >Record Keeping of Transactions
            and Instruction Processing</span>
    </h1>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        19.1<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        may make and retain records of all services, activities and transactions
        undertaken on the Market via the Platform.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        19.2<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        may record and retain telephone conversations between GFI and a
        Participant.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        19.3<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>Each
        Participant agrees to the recording, use and disclosure of telephone
        conversations by GFI to any regulatory authority.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        19.4<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>Each
        Participant must be responsible for maintaining complete and accurate
        records required under Applicable Laws in connection with the
        Participant’s use of the Platform.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        19.5<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>Each
        Participant must, in accordance with Rule 3.11:
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        19.5.1<span
            style="width:14.75pt; text-indent:0pt; display:inline-block">&#xa0;</span>provide
        to GFI such information as GFI reasonably requests in order for GFI to
        fulfil its recordkeeping obligations under Applicable Laws; and
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        19.5.2<span
            style="width:14.75pt; text-indent:0pt; display:inline-block">&#xa0;</span>provide
        GFI, upon request, with the Participant’s records maintained under Rule
        19.4 as soon as reasonably practicable.
    </p>
    <h1
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; font-weight:600; ">
        <span >20.</span><span
            style="width:14.45pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span><span >Notifications</span>
    </h1>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        20.1<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>A
        Participant must notify GFI upon the occurrence of the following events:
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        20.1.1<span
            style="width:14.75pt; text-indent:0pt; display:inline-block">&#xa0;</span>a
        change of name, legal status, LEI, contact details and any significant
        change to the structure of the Participant by giving advanced notice
        where reasonably practicable and, in any event, as soon as reasonably
        practicable;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        20.1.2<span
            style="width:14.75pt; text-indent:0pt; display:inline-block">&#xa0;</span>any
        change which may impact the Participant’s ability to satisfy and
        continue meeting the Eligibility Criteria in Rule 3.3 and the
        operational conditions in Rule 3.4 by giving prompt notice;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        20.1.3<span
            style="width:14.75pt; text-indent:0pt; display:inline-block">&#xa0;</span>any
        change to any information it provided GFI pursuant to these Rules and
        the Agreement as soon as reasonably practicable;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        20.1.4<span
            style="width:14.75pt; text-indent:0pt; display:inline-block">&#xa0;</span>any
        direct or indirect change in ownership or control of the Participant as
        soon as reasonably practicable;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        20.1.5<span
            style="width:14.75pt; text-indent:0pt; display:inline-block">&#xa0;</span>any
        breach of these Rules or any event, act or omission which may affect the
        ability of the Participant to comply with these Rules by giving prompt
        notice;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        20.1.6<span
            style="width:14.75pt; text-indent:0pt; display:inline-block">&#xa0;</span>any
        other action or omission by the Participant or by any other Participant
        which may impair a fair and orderly market and on the Platform by giving
        prompt notice;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        20.1.7<span
            style="width:14.75pt; text-indent:0pt; display:inline-block">&#xa0;</span>any
        of the Participant’s User IDs are lost, stolen or compromised by giving
        prompt notice;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        20.1.8<span
            style="width:14.75pt; text-indent:0pt; display:inline-block">&#xa0;</span>any
        disruptions, malfunctions or breakdown of the systems of the Participant
        which could affect the efficient and orderly functioning of the Market
        or Platform by giving prompt notice;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        20.1.9<span
            style="width:14.75pt; text-indent:0pt; display:inline-block">&#xa0;</span>any
        litigation which could impair or restrict the Participant’s ability to
        comply with these Rules, unless such disclosure is prohibited by law or
        any relevant Regulator by giving prompt notice;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        20.1.10<span
            style="width:9.19pt; text-indent:0pt; display:inline-block">&#xa0;</span>any
        enforcement action, including but without limitation, any warnings,
        suspensions, fines, revocations or prohibitions, imposed by a Regulator
        on the Participant by giving prompt notice other than where restricted
        from doing so under confidentiality obligations imposed by a Regulator
        or by operation of Applicable Law;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        20.1.11<span
            style="width:9.19pt; text-indent:0pt; display:inline-block">&#xa0;</span>an
        Insolvency Event by giving prompt notice;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        20.1.12<span
            style="width:9.19pt; text-indent:0pt; display:inline-block">&#xa0;</span>any
        other events or matters for which a Participant is required to notify
        GFI as specified under these Rules; or
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        20.1.13<span
            style="width:9.19pt; text-indent:0pt; display:inline-block">&#xa0;</span>any
        other significant events or matters which GFI might reasonably expect to
        have brought to its attention by giving prompt notice.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        20.2<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>A
        Participant must make a notification under this Rule 20 by telephone at
        +65 6435 0432 which must be followed by a notice in writing addressed
        to:
    </p>
    <p
        style="margin-left:28.35pt; ; ">
        Compliance Department
    </p>
    <p
        style="margin-left:28.35pt; text-align:justify; ; ">
        GFI Group Pte Ltd, #04-01, Centennial Tower, 3 Temasek Ave, Singapore,
        039190
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-align:justify">
        <span style="; ">Email: </span><a
            href="mailto:compliancesg@gfigroup.com.sg"
            style="text-decoration:none; color:#2962FF;"><span class="Hyperlink"
                style="; ">compliancesg@gfigroup.com.sg</span></a>
    </p>
    <h1
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; font-weight:600; ">
        <span >21.</span><span
            style="width:14.45pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span><span >Complaints</span>
    </h1>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        21.1<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>If
        a Participant wishes to make a complaint to GFI regarding:
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        21.1.1<span
            style="width:14.75pt; text-indent:0pt; display:inline-block">&#xa0;</span>the
        operation of the Market or Platform; or
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        21.1.2<span
            style="width:14.75pt; text-indent:0pt; display:inline-block">&#xa0;</span>the
        conduct of a Participant or any suspicion that a Participant has
        committed a breach of these Rules, such complaint should be made in
        writing (electronic mail acceptable) and addressed to:
    </p>
    <p 
        style="text-indent:34.9pt; text-align:justify; ; ">
        Compliance Department
    </p>
    <p 
        style="text-indent:34.9pt; text-align:justify; ; ">
        GFI Group Pte Ltd
    </p>
    <p 
        style="text-indent:34.9pt; text-align:justify; ; ">
        #04-01, Centennial Tower, 3 Temasek Ave, Singapore, 039190
    </p>
    <p 
        style="margin-bottom:7.5pt; text-indent:34.9pt; text-align:justify">
        <span style="; ">Email: </span><a
            href="mailto:compliancesg@gfigroup.com.sg"
            style="text-decoration:none; color:#2962FF;"><span class="Hyperlink"
                style="; ">compliancesg@gfigroup.com.sg</span></a><span
            style="; "> </span>
    </p>
    <h1
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; font-weight:600; ">
        <span >22.</span><span
            style="width:14.45pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span><span >Data Protection</span>
    </h1>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        22.1<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>Except
        where a Participant has otherwise been notified, when Processing
        Personal Data provided by a Participant in connection with the operation
        of the Market or access to the Platform or otherwise pursuant to these
        Rules, GFI will be a "data controller".
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        22.2<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        will comply with its obligations as a data controller pursuant to the
        Data Protection Laws to which it is subject. These include obligations
        to:
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        22.2.1<span
            style="width:14.75pt; text-indent:0pt; display:inline-block">&#xa0;</span>implement
        appropriate technical and organisational security measures to safeguard
        Personal Data provided by Participants; and
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        22.2.2<span
            style="width:14.75pt; text-indent:0pt; display:inline-block">&#xa0;</span>notify
        relevant Participants of any breach of security leading to the
        accidental or unlawful destruction, loss, alteration, unauthorised
        disclosure of or access to the Personal Data provided by these
        Participants to the extent and within the timescale required by
        applicable Data Protection Laws.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        22.3<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>By
        participating in the Market or accessing the Platform, Participants
        acknowledge and agree:
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify">
        <span style="; ">22.3.1</span><span
            style="width:14.75pt; text-indent:0pt; ; ; display:inline-block">&#xa0;</span><span
            style="; ">to the terms of GFI’s main
            privacy policy, available at </span><a
            href="https://www.gfigroup.com/legal-regulatory/legal/privacy-statement/"
            style="text-decoration:none; color:#2962FF;"><span class="Hyperlink"
                style="; ">https://www.gfigroup.com/legal-regulatory/legal/privacy-statement/</span></a><span
            style="; "> and as updated from time
            to time; </span>
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        22.3.2<span
            style="width:14.75pt; text-indent:0pt; display:inline-block">&#xa0;</span>to
        the terms of any other privacy policy applicable to a Participant in
        relation to its use of the Market and or Platform; and
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        22.3.3<span
            style="width:14.75pt; text-indent:0pt; display:inline-block">&#xa0;</span>that
        they have complied with all applicable Data Protection Laws in their
        provision of Personal Data to GFI.
    </p>
    <h1
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; font-weight:600; ">
        <span >23.</span><span
            style="width:14.45pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span><span >Amendments, Assignments and
            Notices</span>
    </h1>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        23.1<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>GFI
        may in its absolute discretion at any time supplement, amend or revoke
        these Rules partially or wholly.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        23.2<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>Participants
        must be notified of any such change to the Rules by a notice (a
        "<strong>Market Notice</strong>") which will be issued to all
        Participants upon reasonable notice for any material changes and without
        notice for non-material changes.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        23.3<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>Any
        Market Notices issued by GFI notifying Participants of changes to these
        Rules will supplement and form part of these Rules, effective from the
        date specified in the Market Notice.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        23.4<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>For
        the purposes of communication of such Market Notices to Participants, it
        will be sufficient if a Market Notice to Participants is published on
        GFI’s website, provided however, where practicable to do so, GFI will
        also notify Participants by electronic Mail.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        23.5<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>These
        Rules may not be assigned by a Participant to any person without prior
        written consent from GFI.
    </p>
    <h1
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; font-weight:600; ">
        <span >24.</span><span
            style="width:14.45pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span><span >Entire Agreement</span>
    </h1>
    <p
        style="margin-top:7.5pt; margin-bottom:7.5pt; text-align:justify; ; ">
        The Agreement,&#xa0; this Rulebook, and any and all addenda, schedules
        or exhibits attached hereto, represent the entire agreement of the
        parties regarding the subject matter hereof. All other prior agreements,
        understandings, representations or statements, oral or written, relating
        directly to the subject matter hereof, are superseded hereby. There are
        no other oral or written collateral representations, agreements or
        understandings regarding the subject matter hereof. For the avoidance of
        doubt, these Rules relate to participation on the Market&#xa0; only and
        will not be deemed to supersede any agreement, rules or protocol that a
        Participant may have entered into, or may in the future enter into, as a
        client or participant in relation to any other service or electronic
        platform provided by GFI or any of its Affiliates.
    </p>
    <h1
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; font-weight:600; ">
        <span >25.</span><span
            style="width:14.45pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span><span >Signatures</span>
    </h1>
    <p
        style="margin-top:7.5pt; margin-bottom:7.5pt; text-align:justify; ; ">
        Rather than rely on an original signature, GFI may elect to rely on a
        signature that is transmitted, recorded or stored by any electronic,
        optical or similar means (including telecopy, imaging, photocopying,
        electronic mail, electronic data interchange) as if it were (and the
        signature will be considered and have the same effect as) a valid and
        binding original.
    </p>
    <h1
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; font-weight:600; ">
        <span >26.</span><span
            style="width:14.45pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span><span >Termination</span>
    </h1>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        26.1<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>These
        Rules will continue to bind the Participant in relation to outstanding
        and/or unsettled rights and obligations until:
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        26.1.1<span
            style="width:14.75pt; text-indent:0pt; display:inline-block">&#xa0;</span>all
        the Participant’s outstanding Instructions have been executed, accepted,
        cancelled or withdrawn;
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        26.1.2<span
            style="width:14.75pt; text-indent:0pt; display:inline-block">&#xa0;</span>all
        outstanding obligations of that Participant with respect to such
        Instructions and any resultant transactions have been performed; and
    </p>
    <p
        style="margin-top:7.5pt; margin-left:70.9pt; margin-bottom:7.5pt; text-indent:-42.55pt; text-align:justify; ; ">
        26.1.3<span
            style="width:14.75pt; text-indent:0pt; display:inline-block">&#xa0;</span>all
        other outstanding obligations of that Participant under these Rules have
        been Performed.
    </p>
    <p
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; ; ">
        26.2<span
            style="width:8.89pt; text-indent:0pt; display:inline-block">&#xa0;</span>After
        any Participant has ceased to be a Participant, it will continue to be
        liable for its acts and/or omissions that occurred at any time before it
        ceased to be a Participant on the Market and/or access the Platform.
    </p>
    <h1
        style="margin-top:7.5pt; margin-left:28.35pt; margin-bottom:7.5pt; text-indent:-28.35pt; text-align:justify; font-weight:600; ">
        <span >27.</span><span
            style="width:14.45pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span><span >Governing Law</span>
    </h1>
    <p 
        style="margin-top:7.5pt; margin-left:46.5pt; text-indent:-28.5pt; text-align:justify; ; ">
        <span>27.1</span><span
            style="width:9.04pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span>This Rulebook and all non-contractual obligations arising out of
        or in connection with this Rulebook will be governed by and construed in
        accordance with the laws of the Republic of Singapore.
    </p>
    <p 
        style="margin-top:7.5pt;margin-left:46.5pt; margin-bottom:7.5pt; text-indent:-28.5pt; text-align:justify; ; ">
        <span>27.2</span><span
            style="width:9.04pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span>Any disputes arising out of or in connection with these Rules,
        including any question regarding its existence, validity or termination,
        shall be referred to and finally resolved by arbitration in Singapore in
        accordance with the arbitration rules of the Singapore International
        Arbitration Centre (“<strong>SIAC Rules</strong>”) for the time being in
        force.&#xa0; The tribunal shall consist of three (3) arbitrators. The
        language of the arbitration shall be English.&#xa0;
    </p>
</div>`;

  return (
    <div className={`text-sm ${secondary ? 'bg-basic-200 text-gray-100 p-20' : ''}`}>
      <div dangerouslySetInnerHTML={{ __html: para }}></div>
    </div>
  );
};

export default TermsAndConditionContent;
